<template>
    <div class="faq">
        <Header :pageId1="pageId" :cId1="cId" />
        <div class="banner">
            <div class="container">
                <div class="title">Sell My Car Brisbane</div>
            </div>
        </div>

        <div class="main">
            <div class="container">
                <div class="warpper">
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h2>I Want to Sell My Car in Brisbane</h2>
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <p>Are you tired of your wheels? Do you not feel inclined to climb the mountain of forms and
                                certificates necessary if you sell your car through an ad in the paper? Is your ride younger
                                than ten years and has less than 150 000 km on the clock? If you answer yes to these three
                                questions, then the follow-up question of "How do I <a style="color: #0c61b9;" @click="goPage1">sell my car in
                                    Brisbane</a>?" has just one easy answer: through us!</p>
                            <p>We are registered and licensed vehicle dealers interested in all makes and models of cars,
                                SUVs, Utes, station-wagons, trucks, and super sports cars anywhere in Australia, and we pay
                                cash in your bank within 24 hours.</p>
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>Can I Sell My Car in Perth to You?</h3>
                            <p>Absolutely! We have an extensive network of evaluators, inspectors, and collectors across the
                                whole of Australia and Tasmania to quickly assess the true value of your vehicle and pick it
                                up after you've received your payment. The whole process is a breeze, and we'll explain it
                                shortly later.</p>
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-6">
                            <ul>
                                <li>Our team members across the country are all experienced hands in the automotive trade.
                                    They understand that your vehicle has value and that it is not brand new anymore. The
                                    objective is to offer you the highest price possible while still leaving a profit margin
                                    for us when we resell your vehicle.</li>
                                <li>The whole team operates on trust, which seems counter-intuitive when talking about
                                    second-hand car dealers! We trust our representatives to give you a fair price for your
                                    car because then you'll send other sellers in our direction by way of a positive
                                    recommendation when they ask: "Where can I sell my car in Adelaide for a good price?"
                                </li>
                                <li>On the flip side, the representative must help us acquire your hatchback at the lowest
                                    possible price to make a substantial profit on the resell. If he does not achieve this,
                                    then we'll not use him as our representative in the future. So, as you've probably
                                    realised, the price we offer for your 4x4 is a negotiated compromise that leaves all
                                    parties smiling.</li>
                            </ul>
                        </div>
                        <div class="ddCol-6">
                            <img alt="sell cars from perth" src="~@/assets/images/choose.png">
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>How Do I Sell My Car in Melbourne to You?</h3>
                            <p>You start by filling in the basic assessment form on our website, allowing us to generate a
                                standard offer based on the trade value of your model of vehicle. If you find this offer
                                acceptable, then we'll start the serious negotiations to purchase your car by initiating the
                                following steps:</p>
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-6">
                            <img alt="sell cars from melbourne" src="~@/assets/images/row2_l.png">
                        </div>
                        <div class="ddCol-6">
                            <ul>
                                <li>One of our many representatives across the country will arrange a meeting to inspect
                                    your vehicle. As previously mentioned, this is done to assess the true value of your
                                    luxury sedan. The inspection is done visually, as our guys are sharp and can detect a
                                    worn tappet at fifty paces. They'll also inspect the service history, so have that at
                                    hand.</li>
                                <li>We welcome enthusiastic haggling, and we'll be as accommodating as possible based on our
                                    impression of your Ute. Once satisfied, we'll either confirm the offer or fairly modify
                                    the price according to the condition of your roadster. It is essential that the price
                                    negotiated is deemed fair by all parties.</li>
                                <li>Once the negotiations are concluded, a deed of sale is signed, and we'll deposit the
                                    money into your nominated bank account within 24 hours, weekends included. The vehicle
                                    is now ours, and we'll send a beefy guy with a tow truck right over to collect the
                                    four-wheeler, keys, and manuals – and the stuffed koala doll in the rear window.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>What About Darwin, Alice Springs, and Coober Pedy?</h3>
                            <p>We got you covered even there, right as if you had asked: "How do I sell my car in Canberra?"
                                We say that we buy cars anywhere in Australia, not just in the major cities. We also come to
                                you, so you don't have to drive to Brisbane to sell your car to us.</p>
                            <p><a  style="color: #0c61b9;" @click="goPage">Sell My Cars Fast</a> is the answer when you want to sell your car to
                                a trusted trader that pays competitive prices within 24 hours of concluding the deal. So
                                don't be left in the lurch when you ask: "Where do I sell my car in Hobart?" The answer is
                                the same as for: "Where do I sell my car in Sydney?" — Where ever you are in Australia to
                                <strong>Sell My Cars Fast!</strong>
                            </p>
                        </div>
                    </div>

                </div>

            </div>

        </div>
        <Footer />
    </div>
</template>
  
<script>
import Header from "@/components/header/Header";
import Footer from "@/components/footer/Footer";

export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {};
    },
    methods:{
        goPage(){
            this.$router.push({ name: "About", query: { index: 1 } });
        },
        goPage1(){
            this.$router.push({ name: "FAQ", query: { index: 2 } });
        }
    },
    watch: {
        $route: {
            deep: true,
            handler(val) {
                this.pageId = val.query.id;
                this.cId = val.query.cId;
            },
        },
    },
    created() {
        this.pageId = this.$route.query.id;
        this.cId = this.$route.query.cId;
        this.pageName = this.$route.query.name;
    },
};
</script>
  
<style scoped lang="less">
// @import "../../common/css/bootstrap.min.css";
.ddRow {  font-family: Ubuntu-Regular, Ubuntu; color: rgba(0, 0, 0, 0.6); display: flex; align-items:center; margin: 1% 0; width: 100%; }
.ddRow h2, .ddRow h3 { font-weight: bold; color: #004AAD; margin: 4% 0 2% 0; text-align: center; }
.ddRow ul li { list-style-type: disclosure-closed; margin-bottom: 2%; margin-left: 4%; }
.ddRow img { width: 100%; }
.ddCol-12 { width: 100%; }
.ddCol-6 { width: 50%; padding: 2%; float: left; }
.ddRow:after { content: ""; display: table; clear: both; }
@media only screen and (max-device-width: 960px) { .ddRow { display: block; } .ddCol-6 { width: 100%; } }
.faq {
    width: 100%;
    position: relative;

    .banner {
        width: 100%;
        height: 286px;
        background: url("~@/assets/images/banner_3.png") no-repeat;
        background-size: cover;
        background-position: center;
        padding: 160px 0 80px 0;
        box-sizing: border-box;

        .title {
            font-size: 40px;
            font-family: Ubuntu-Bold, Ubuntu;
            font-weight: bold;
            color: #ffffff;
            line-height: 47px;
            text-align: center;
        }
    }

    .main {
        width: 100%;

        .warpper {
            padding: 80px 0;
            box-sizing: border-box;


            p {
                font-family: Ubuntu-Regular, Ubuntu;
                font-weight: 400;
                margin-bottom: 32px;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.6);
                line-height: 19px;

                span {
                    font-weight: bold;
                }
            }

            .title {
                font-size: 24px;
                font-family: Ubuntu-Bold, Ubuntu;
                font-weight: 800;
                color: rgba(0, 0, 0, 0.9);
                margin-bottom: 20px;
            }

            .line {
                width: 100%;
                height: 1px;
                background: #eaeaea;
                margin-top: 40px;
                margin-bottom: 40px;
            }
        }
    }
}

@media only screen and (max-width: 690px) {
    .faq {
        .banner {
            height: 132px;
            padding: 84px 0 20px 0;

            .title {
                font-size: 24px;
                line-height: 28px;
            }
        }

        .main {
            width: 299px;
            margin: 0 auto;

            .warpper {
                padding: 20px 0;

                p {
                    font-size: 10px;
                    line-height: 15px;
                    margin-bottom: 20px;
                }

                .title {
                    font-size: 12px;
                    line-height: 14px;
                    margin-bottom: 10px;
                }

                .line {
                    margin: 0 0 20px 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 1050px) and (min-width: 690px) {
    .faq {
        .banner {
            height: 132px;
            padding: 84px 0 20px 0;

            .title {
                font-size: 24px;
                line-height: 28px;
            }
        }

        .main {
            width: 299px;
            margin: 0 auto;

            .warpper {
                padding: 20px 0;

                p {
                    font-size: 10px;
                    line-height: 15px;
                    margin-bottom: 20px;
                }

                .title {
                    font-size: 12px;
                    line-height: 14px;
                    margin-bottom: 10px;
                }

                .line {
                    margin: 0 0 20px 0;
                }
            }
        }
    }
}
</style>
  